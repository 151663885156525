<template>
  <!-- new groups Event -->
  <div v-if="eventName == 'new_groups'">
    <v-row style="margin-bottom: 30px;">
      <v-col class="mt-2" :cols="6">
        <div>
          <label
            style="
              font-size: 18px;
              background-color: rgb(213, 33, 13);
              padding: 2px 18px;
              color: #fff;
            "
            >{{ $t("OldData") }}</label
          >
        </div>

        <table v-if="oldDetails && oldDetails.length"  >
            <thead>
              <tr>
                <th>{{ $t("ID") }}</th>
                <th>{{ $t("Name") }}</th>
                <th>{{ $t("Price") }}</th>
                <th>{{ $t("OfferPrice") }}</th>
                <th>{{ $t("Quantity") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(oldItem, index) in oldDetails" :key="index">
                <td :style="compareAndHighlight('id', oldItem.id)">{{ oldItem.id }}</td>
                <td :style="compareAndHighlight('name', oldItem.id)">
                  {{ oldItem.name }}
                </td>
                <td :style="compareAndHighlight('price', oldItem.id)">
                  {{ oldItem.price }}
                </td>
                <td :style="compareAndHighlight('offer_price', oldItem.id)">
                  {{ oldItem.offer_price }}
                </td>
                <td :style="compareAndHighlight('quantity', oldItem.id)">
                  {{ oldItem.quantity }}
                </td>
              </tr>
            </tbody>
          </table>

      <div v-else style="margin-top: 15px; margin-right: 10px">
        <h2 style="color: red">{{ $t("NoData") }}</h2>
      </div>

      </v-col>

      <v-col class="mt-2" :cols="6">
        <div>
          <label
            style="
              font-size: 18px;
              background-color: rgb(11, 195, 24);
              padding: 2px 18px;
              color: #fff;
            "
            >{{ $t("StatusData") }}</label
          >
        </div>

        <table v-if="statusDetails && statusDetails.length" class="mt-10 mx-2 full-width-table">
            <thead>
              <tr>
                <th>{{ $t("ID") }}</th>
                <th>{{ $t("Name") }}</th>
                <th>{{ $t("Price") }}</th>
                <th>{{ $t("OfferPrice") }}</th>
                <th>{{ $t("Quantity") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(newItem, index) in statusDetails" :key="index">
                <td :style="compareAndHighlight('id', newItem.id)">{{ newItem.id }}</td>
                <td :style="compareAndHighlight('name', newItem.id)">
                  {{ newItem.name }}
                </td>
                <td :style="compareAndHighlight('price', newItem.id)">
                  {{ newItem.price }}
                </td>
                <td :style="compareAndHighlight('offer_price', newItem.id)">
                  {{ newItem.offer_price }}
                </td>
                <td :style="compareAndHighlight('quantity', newItem.id)">
                  {{ newItem.quantity }}
                </td>
              </tr>
            </tbody>
          </table>

          <div v-else style="margin-top: 15px; margin-right: 10px">
        <h2 style="color: red">{{ $t("NoData") }}</h2>
          </div>


      </v-col>

   

    </v-row>
    </div>
  </template>

<script>
import moment from 'moment';

export default {
  props: {
    eventName: String,
    oldDetails: Array,
    statusDetails: Array,
    daysOfWeek: Array,
  },
  methods: {
    compareAndHighlight(field, id) {
  if (!this.oldDetails || !this.statusDetails) {
    return {};
  }

  const oldItem = this.oldDetails.find((item) => item.id === id);
  const newItem = this.statusDetails.find((item) => item.id === id);

  // If newItem exists but oldItem is missing, highlight as a difference
  if (!oldItem && newItem) {
    return {
      backgroundColor: "yellow",
      fontWeight: "bold",
    };
  }

  // If both exist, compare values after converting to numbers if they are numeric
  if (oldItem && newItem) {
    const oldValue = this.normalizeValue(oldItem[field]);
    const newValue = this.normalizeValue(newItem[field]);

    if (oldValue !== newValue) {
      return {
        backgroundColor: "yellow",
        fontWeight: "bold",
      };
    }
  }

  return {};
},

normalizeValue(value) {
  // Convert numeric values from strings like "180.00" to numbers like 180
  if (!isNaN(value)) {
    return parseFloat(value); // Convert to number
  }
  return value; // Return original value if not a number
},
  

  },
};
</script>

<style scoped>
  table {
    width: 100%;
    border-collapse: collapse;
    position: relative;
    top: 30px;
    right: -5px;
  }

  table th, table td {
    padding: 5px 10px;
    border: 1px solid #ddd;
    text-align: center;
  }

  table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  table td {
    background-color: #fff;
  }

</style>
