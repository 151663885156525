<template>
   <div v-if="eventName == 'address'">

<v-row>
<v-col class="mt-2" :cols="12">
  <div>
    <label style="font-size: 18px;
    background-color: rgb(213, 33, 13)
    ;padding: 2px 18px;
    color: #fff;
    
    "
    >{{ $t("OldData") }}</label>
  </div>
</v-col>
            
<v-row v-if="oldDetails" style="margin-top: 15px;margin-right: 10px;">

  <v-col :style="compareAndHighlight('id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('ID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('city_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('CityID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.city_id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('city')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('City') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.city }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('type')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Type') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.type }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('period')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Period') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.period }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('street')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Street') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.street }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('address')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Address') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.address }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('longitude')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Longitude') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.longitude }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('latitude')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Latitude') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.latitude }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('company_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('CompanyID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.company_id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('building_no')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('BuildingNo') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.building_no }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('customer_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('CustomerID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.customer_id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('district_area')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('DistrictArea') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.district_area }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('location_link')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('LocationLink') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.location_link }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('contact_number')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('ContactNumber') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.contact_number }}
        </span> 
      </p>
    </v-col>

    <v-col style="position:relative;bottom:50px;margin-bottom: -50px" :style="compareAndHighlight('location_image')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('LocationImage') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          
          <a :href="oldDetails.location_image || 'https://static.vecteezy.com/system/resources/previews/004/141/669/original/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'"  target="_blank">
            <img class="mx-5" 
     style="position:relative; top:25px; width:70px;cursor: pointer;border-radius: 50%;" 
     :src="oldDetails.location_image || 'https://static.vecteezy.com/system/resources/previews/004/141/669/original/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'" 
     alt="Profile Image" />
          </a>


        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('notes')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Notes') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.notes }}
        </span> 
      </p>
    </v-col>


 </v-row>

  <v-row v-else style="margin-top: 15px;margin-right: 10px;">
      <h2 style="color: red;">{{ $t('NoData') }}</h2>
  </v-row>

  <v-col class="mt-10" :cols="12">
    <div>
      <label style="font-size: 18px;
      background-color: rgb(11, 195, 24)
      ;padding: 2px 18px;
      color: #fff;
      
      "
      >{{ $t("StatusData") }}</label>
    </div>
  </v-col>
</v-row>

<v-row v-if="statusDetails" style="margin-top: 15px;margin-right: 10px;">

<v-col :style="compareAndHighlight('id')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('ID') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.id }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('city_id')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('CityID') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.city_id }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('city')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('City') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.city }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('type')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('Type') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.type }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('period')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('Period') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.period }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('street')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('Street') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.street }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('address')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('Address') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.address }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('longitude')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('Longitude') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.longitude }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('latitude')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('Latitude') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.latitude }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('company_id')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('CompanyID') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.company_id }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('building_no')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('BuildingNo') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.building_no }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('customer_id')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('CustomerID') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.customer_id }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('district_area')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('DistrictArea') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.district_area }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('location_link')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('LocationLink') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.location_link }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('contact_number')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('ContactNumber') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.contact_number }}
      </span> 
    </p>
  </v-col>

  <v-col style="position:relative;bottom:50px;margin-bottom: -50px" :style="compareAndHighlight('location_image')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('LocationImage') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          
          <a :href="statusDetails.location_image || 'https://static.vecteezy.com/system/resources/previews/004/141/669/original/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'"  target="_blank">
            <img class="mx-5" 
     style="position:relative; top:25px; width:70px;cursor: pointer;border-radius: 50%;" 
     :src="statusDetails.location_image || 'https://static.vecteezy.com/system/resources/previews/004/141/669/original/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'" 
     alt="Profile Image" />
          </a>


        </span> 
      </p>
    </v-col>

  <v-col :style="compareAndHighlight('notes')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('Notes') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.notes }}
      </span> 
    </p>
  </v-col>


</v-row>
<v-row v-else style="margin-top: 15px;margin-right: 10px;">
    <h2 style="color: red;">{{ $t('NoData') }}</h2>
</v-row>
</div>
</template>


<script>
import moment from 'moment';

export default {
  props: {
    eventName: String,
    oldDetails: Object,
    statusDetails: Object,
    daysOfWeek: Array,
  },
  methods: {
    compareAndHighlight(field) {
      if (!this.oldDetails || !this.statusDetails) {
        return {};
      }

      if (
        Array.isArray(this.oldDetails[field]) &&
        Array.isArray(this.statusDetails[field])
      ) {
        // Compare arrays
        if (
          this.oldDetails[field].length != this.statusDetails[field].length ||
          !this.oldDetails[field].every(
            (value, index) => value == this.statusDetails[field][index]
          )
        ) {
          return { backgroundColor: "yellow", fontWeight: "bold" };
        }
      } else if (field == "start_date" || field == "end_date") {
        // Handle date comparison
        const oldDate = this.dateCreatedAt(this.oldDetails[field]);
        const newDate = this.dateCreatedAt(this.statusDetails[field]);
        if (oldDate != newDate) {
          return { backgroundColor: "yellow", fontWeight: "bold" };
        }
      } else if (this.oldDetails[field] != this.statusDetails[field]) {
        // Handle other fields
        return { backgroundColor: "yellow", fontWeight: "bold" };
      }

      return {};
    },
    dateCreatedAt(created_at) {
      if (created_at) return moment(created_at).format("YYYY-MM-DD");
      else return null;
    },
  },
};
</script>

<style>
</style>