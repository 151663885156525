<template>
  <!-- Subscribe Event -->
  <div v-if="eventName == 'subscription'">
    <v-row>
      <v-col class="mt-2" :cols="12">
        <div>
          <label
            style="
              font-size: 18px;
              background-color: rgb(213, 33, 13);
              padding: 2px 18px;
              color: #fff;
            "
            >{{ $t("OldData") }}</label
          >
        </div>
      </v-col>

      <v-row v-if="oldDetails" style="margin-top: 15px; margin-right: 10px">
        <v-col :style="compareAndHighlight('package_name')" :cols="4">
          <p>
            <span style="color: blue; font-weight: bold">
              {{ $t("PackageName") }} :
            </span>
            <span style="color: #000; font-weight: bold">
              {{ oldDetails.package_name }}
            </span>
          </p>
        </v-col>

        <v-col :cols="8">
          <p>
            <span style="color: blue; font-weight: bold">
              {{ $t("Days") }} :
            </span>
            <span
              v-if="oldDetails.days && oldDetails.days.length"
              :style="compareAndHighlight('days')"
              style="color: #000; font-weight: bold"
            >
              <span v-for="(day, index) in oldDetails.days" :key="index">
                {{
                  daysOfWeek.find((d) => d.id === day)
                    ? daysOfWeek.find((d) => d.id === day).name
                    : ""
                }}
                <span v-if="index !== oldDetails.days.length - 1">, </span>
              </span>
            </span>
          </p>
        </v-col>

        <v-col :cols="4">
          <p>
            <span style="color: blue; font-weight: bold">
              {{ $t("PackageID") }} :
            </span>
            <span
              :style="compareAndHighlight('package_id')"
              style="color: #000; font-weight: bold"
            >
              {{ oldDetails.package_id }}
            </span>
          </p>
        </v-col>

        <v-col :cols="4">
          <p>
            <span style="color: blue; font-weight: bold">
              {{ $t("StartDate") }} :
            </span>
            <span
              :style="compareAndHighlight('start_date')"
              style="color: #000; font-weight: bold"
            >
              {{ dateCreatedAt(oldDetails.start_date) }}
            </span>
          </p>
        </v-col>

        <v-col :cols="4">
          <p>
            <span style="color: blue; font-weight: bold">
              {{ $t("EndDate") }} :
            </span>
            <span
              :style="compareAndHighlight('end_date')"
              style="color: #000; font-weight: bold"
            >
              {{ dateCreatedAt(oldDetails.end_date) }}
            </span>
          </p>
        </v-col>

        <v-col :cols="4">
          <p>
            <span style="color: blue; font-weight: bold">
              {{ $t("Paid") }} :
            </span>
            <span
              :style="compareAndHighlight('is_paid')"
              style="color: #000; font-weight: bold"
            >
              {{ oldDetails.is_paid == 1 ? $t("Yes") : $t("No") }}
            </span>
          </p>
        </v-col>
        <v-col :cols="4">
          <p>
            <span style="color: blue; font-weight: bold">
              {{ $t("From mobile") }} :
            </span>
            <span
              :style="compareAndHighlight('from_mobile')"
              style="color: #000; font-weight: bold"
            >
              {{ oldDetails.from_mobile == 1 ? $t("Yes") : $t("No") }}
            </span>
          </p>
        </v-col>

        <v-col :cols="4">
          <p>
            <span style="color: blue; font-weight: bold">
              {{ $t("National day") }} :
            </span>
            <span
              :style="compareAndHighlight('national_day')"
              style="color: #000; font-weight: bold"
            >
              {{ oldDetails.national_day == 1 ? $t("Yes") : $t("No") }}
            </span>
          </p>
        </v-col>
      </v-row>

      <v-row v-else style="margin-top: 15px; margin-right: 10px">
        <h2 style="color: red">{{ $t("NoData") }}</h2>
      </v-row>

      <v-col class="mt-10" :cols="12">
        <div>
          <label
            style="
              font-size: 18px;
              background-color: rgb(11, 195, 24);
              padding: 2px 18px;
              color: #fff;
            "
            >{{ $t("StatusData") }}</label
          >
        </div>
      </v-col>
    </v-row>

    <v-row v-if="statusDetails" style="margin-top: 15px; margin-right: 10px">
      <v-col :cols="4">
        <p>
          <span style="color: blue; font-weight: bold">
            {{ $t("PackageName") }} :
          </span>
          <span
            :style="compareAndHighlight('package_name')"
            style="color: #000; font-weight: bold"
          >
            {{ statusDetails.package_name }}
          </span>
        </p>
      </v-col>

      <v-col :cols="8">
        <p>
          <span style="color: blue; font-weight: bold">
            {{ $t("Days") }} :
          </span>
          <span
            v-if="statusDetails.days && statusDetails.days.length"
            style="color: #000; font-weight: bold"
            :style="compareAndHighlight('days')"
          >
            <span v-for="(day, index) in statusDetails.days" :key="index">
              {{
                daysOfWeek.find((d) => d.id === day)
                  ? daysOfWeek.find((d) => d.id === day).name
                  : ""
              }}
              <span v-if="index !== statusDetails.days.length - 1">, </span>
            </span>
          </span>
        </p>
      </v-col>

      <v-col :cols="4">
        <p>
          <span style="color: blue; font-weight: bold">
            {{ $t("PackageID") }} :
          </span>
          <span
            :style="compareAndHighlight('package_id')"
            style="color: #000; font-weight: bold"
          >
            {{ statusDetails.package_id }}
          </span>
        </p>
      </v-col>

      <v-col :cols="4">
        <p>
          <span style="color: blue; font-weight: bold">
            {{ $t("StartDate") }} :
          </span>
          <span
            :style="compareAndHighlight('start_date')"
            style="color: #000; font-weight: bold"
          >
            {{ dateCreatedAt(statusDetails.start_date) }}
          </span>
        </p>
      </v-col>

      <v-col :cols="4">
        <p>
          <span style="color: blue; font-weight: bold">
            {{ $t("EndDate") }} :
          </span>
          <span
            :style="compareAndHighlight('end_date')"
            style="color: #000; font-weight: bold"
          >
            {{ dateCreatedAt(statusDetails.end_date) }}
          </span>
        </p>
      </v-col>

      <v-col :cols="4">
        <p>
          <span style="color: blue; font-weight: bold">
            {{ $t("Paid") }} :
          </span>
          <span
            :style="compareAndHighlight('is_paid')"
            style="color: #000; font-weight: bold"
          >
            {{ statusDetails.is_paid == 1 ? $t("Yes") : $t("No") }}
          </span>
        </p>
      </v-col>
      <v-col :cols="4">
        <p>
          <span style="color: blue; font-weight: bold">
            {{ $t("From mobile") }} :
          </span>
          <span
            :style="compareAndHighlight('from_mobile')"
            style="color: #000; font-weight: bold"
          >
            {{ statusDetails.from_mobile == 1 ? $t("Yes") : $t("No") }}
          </span>
        </p>
      </v-col>

      <v-col :cols="4">
        <p>
          <span style="color: blue; font-weight: bold">
            {{ $t("National day") }} :
          </span>
          <span
            :style="compareAndHighlight('national_day')"
            style="color: #000; font-weight: bold"
          >
            {{ statusDetails.national_day == 1 ? $t("Yes") : $t("No") }}
          </span>
        </p>
      </v-col>
    </v-row>

    <v-row v-else style="margin-top: 15px; margin-right: 10px">
      <h2 style="color: red">{{ $t("NoData") }}</h2>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    eventName: String,
    oldDetails: Object,
    statusDetails: Object,
    daysOfWeek: Array,
  },
  methods: {
    compareAndHighlight(field) {
      if (!this.oldDetails || !this.statusDetails) {
        return {};
      }

      if (
        Array.isArray(this.oldDetails[field]) &&
        Array.isArray(this.statusDetails[field])
      ) {
        // Compare arrays
        if (
          this.oldDetails[field].length != this.statusDetails[field].length ||
          !this.oldDetails[field].every(
            (value, index) => value == this.statusDetails[field][index]
          )
        ) {
          return { backgroundColor: "yellow", fontWeight: "bold" };
        }
      } else if (field == "start_date" || field == "end_date") {
        // Handle date comparison
        const oldDate = this.dateCreatedAt(this.oldDetails[field]);
        const newDate = this.dateCreatedAt(this.statusDetails[field]);
        if (oldDate != newDate) {
          return { backgroundColor: "yellow", fontWeight: "bold" };
        }
      } else if (this.oldDetails[field] != this.statusDetails[field]) {
        // Handle other fields
        return { backgroundColor: "yellow", fontWeight: "bold" };
      }

      return {};
    },
    dateCreatedAt(created_at) {
      if (created_at) return moment(created_at).format("YYYY-MM-DD");
      else return null;
    },
  },

  created() {
    console.log('yyy');
    console.log('old', this.oldDetails);
  }
};
</script>

<style>
</style>