<template>
  <div v-if="eventName == 'profile'">
<v-row>
<v-col class="mt-2" :cols="12">
  <div>
    <label style="font-size: 18px;
    background-color: rgb(213, 33, 13)
    ;padding: 2px 18px;
    color: #fff;
    
    "
    >{{ $t("OldData") }}</label>
  </div>
</v-col>
            
<v-row v-if="oldDetails" style="margin-top: 15px;margin-right: 10px;">
    <v-col :style="compareAndHighlight('first_name')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('FirstName') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.first_name }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('second_name')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('SecondName') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.second_name }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('sur_name')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('SurName') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.sur_name }}
        </span> 
      </p>
    </v-col>



    <v-col :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Gender') }} : </span> 
        <span :style="compareAndHighlight('gender')"  style="color:#000;font-weight:bold;"> 
          {{ oldDetails.gender == 'male' ? $t('Male'): $t('Female') }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('carb')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Carb') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.carb }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('protein')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Protein') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.protein }}
        </span> 
      </p>
    </v-col>


    <v-col :style="compareAndHighlight('current_weight')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('CurrentWeight') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.current_weight }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('dietary_plan')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('DietaryPlan') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.dietary_plan }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('goal')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Goal') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.goal }}
        </span> 
      </p>
    </v-col>
    <v-col style="position:relative;bottom:50px;margin-bottom: -50px" :style="compareAndHighlight('image_profile')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Image Profile') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          
          <a :href="oldDetails.image_profile || 'https://static.vecteezy.com/system/resources/previews/004/141/669/original/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'"  target="_blank">
            <img class="mx-5" 
     style="position:relative; top:25px; width:70px;cursor: pointer;border-radius: 50%;" 
     :src="oldDetails.image_profile || 'https://static.vecteezy.com/system/resources/previews/004/141/669/original/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'" 
     alt="Profile Image" />
          </a>


        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('weight')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Weight') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.weight }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('height')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Height') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.height }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('targeted_weight')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('TargetedWeight') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.targeted_weight }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('weight_rate')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('WeightRate') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.weight_rate }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('plus_minus_calories')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('PlusMinusCalories') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.plus_minus_calories }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('workout_plan')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('WorkoutPlan') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.workout_plan }}
        </span> 
      </p>
    </v-col>
 </v-row>

  <v-row v-else style="margin-top: 15px;margin-right: 10px;">
      <h2 style="color: red;">{{ $t('NoData') }}</h2>
  </v-row>

  <v-col class="mt-10" :cols="12">
    <div>
      <label style="font-size: 18px;
      background-color: rgb(11, 195, 24)
      ;padding: 2px 18px;
      color: #fff;
      
      "
      >{{ $t("StatusData") }}</label>
    </div>
  </v-col>
</v-row>


<v-row v-if="statusDetails" style="margin-top: 15px;margin-right: 10px;">
    <v-col :style="compareAndHighlight('first_name')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('FirstName') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.first_name }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('second_name')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('SecondName') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.second_name }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('sur_name')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('SurName') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.sur_name }}
        </span> 
      </p>
    </v-col>



    <v-col :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Gender') }} : </span> 
        <span :style="compareAndHighlight('gender')"  style="color:#000;font-weight:bold;"> 
          {{ statusDetails.gender == 'male' ? $t('Male'): $t('Female') }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('carb')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Carb') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.carb }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('protein')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Protein') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.protein }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('current_weight')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('CurrentWeight') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.current_weight }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('dietary_plan')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('DietaryPlan') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.dietary_plan }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('goal')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Goal') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.goal }}
        </span> 
      </p>
    </v-col>
    <v-col style="position:relative;bottom:50px;margin-bottom: -50px" :style="compareAndHighlight('image_profile')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Image Profile') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          
          <a :href="statusDetails.image_profile || 'https://static.vecteezy.com/system/resources/previews/004/141/669/original/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'"  target="_blank">
            <img class="mx-5" 
     style="position:relative; top:25px; width:70px;cursor: pointer;border-radius: 50%;" 
     :src="statusDetails.image_profile || 'https://static.vecteezy.com/system/resources/previews/004/141/669/original/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'" 
     alt="Profile Image" />
          </a>
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('weight')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Weight') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.weight }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('height')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Height') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.height }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('targeted_weight')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('TargetedWeight') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.targeted_weight }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('weight_rate')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('WeightRate') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.weight_rate }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('plus_minus_calories')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('PlusMinusCalories') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.plus_minus_calories }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('workout_plan')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('WorkoutPlan') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.workout_plan }}
        </span> 
      </p>
    </v-col>
 </v-row>

<v-row v-else style="margin-top: 15px;margin-right: 10px;">
    <h2 style="color: red;">{{ $t('NoData') }}</h2>
</v-row>
</div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    eventName: String,
    oldDetails: Object,
    statusDetails: Object,
    daysOfWeek: Array,
  },
  methods: {
    compareAndHighlight(field) {
      if (!this.oldDetails || !this.statusDetails) {
        return {};
      }

      if (
        Array.isArray(this.oldDetails[field]) &&
        Array.isArray(this.statusDetails[field])
      ) {
        // Compare arrays
        if (
          this.oldDetails[field].length != this.statusDetails[field].length ||
          !this.oldDetails[field].every(
            (value, index) => value == this.statusDetails[field][index]
          )
        ) {
          return { backgroundColor: "yellow", fontWeight: "bold" };
        }
      } else if (field == "start_date" || field == "end_date") {
        // Handle date comparison
        const oldDate = this.dateCreatedAt(this.oldDetails[field]);
        const newDate = this.dateCreatedAt(this.statusDetails[field]);
        if (oldDate != newDate) {
          return { backgroundColor: "yellow", fontWeight: "bold" };
        }
      } else if (this.oldDetails[field] != this.statusDetails[field]) {
        // Handle other fields
        return { backgroundColor: "yellow", fontWeight: "bold" };
      }

      return {};
    },
    dateCreatedAt(created_at) {
      if (created_at) return moment(created_at).format("YYYY-MM-DD");
      else return null;
    },
  },
};
</script>

<style>
</style>