<template>
  <v-row class="mt-0" v-if="details && details.customer_data">
            <v-col cols="12" sm="4">
              <div class="customCard">
                <p>{{ $t('SubscriberName') }}: 
                  <span> {{ details.customer_data.name }} </span>
                </p>
              </div>
            </v-col>

      

      <v-col cols="12" sm="4">
        <div class="customCard">
          <p>{{ $t('Email') }}: 
            <span>  {{ details.customer_data.email }} </span>
          </p>
        </div>
      </v-col>


      <v-col cols="12" sm="4">
        <div class="customCard">
          <p>{{ $t('Mobile') }}: 
              <span> 
                {{ details.customer_data.phone_number }}
              </span>
          </p>
        </div>
      </v-col>


      </v-row>
</template>


<script>

export default {
  props: {
    details: Object,
  },
};
</script>

<style>
</style>