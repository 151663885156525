<template>
   <div v-if="eventName == 'customers'">

<v-row>
<v-col class="mt-2" :cols="12">
  <div>
    <label style="font-size: 18px;
    background-color: rgb(213, 33, 13)
    ;padding: 2px 18px;
    color: #fff;
    
    "
    >{{ $t("OldData") }}</label>
  </div>
</v-col>
            
<v-row v-if="oldDetails" style="margin-top: 15px;margin-right: 10px;">

  <v-col :style="compareAndHighlight('id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('CustomerID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('name')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Name') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.name }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('mobile')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Mobile') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.mobile }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('phone_number')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Mobile') }} 2 : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.phone_number }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('email')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Email') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.email }}
        </span> 
      </p>
    </v-col>


 </v-row>

  <v-row v-else style="margin-top: 15px;margin-right: 10px;">
      <h2 style="color: red;">{{ $t('NoData') }}</h2>
  </v-row>

  <v-col class="mt-10" :cols="12">
    <div>
      <label style="font-size: 18px;
      background-color: rgb(11, 195, 24)
      ;padding: 2px 18px;
      color: #fff;
      
      "
      >{{ $t("StatusData") }}</label>
    </div>
  </v-col>
</v-row>


<v-row v-if="statusDetails" style="margin-top: 15px;margin-right: 10px;">

<v-col :style="compareAndHighlight('id')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('CustomerID') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.id }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('name')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('Name') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.name }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('mobile')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('Mobile') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.mobile }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('phone_number')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('Mobile') }} 2 : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.phone_number }}
      </span> 
    </p>
  </v-col>

  <v-col :style="compareAndHighlight('email')" :cols="4">
    <p> 
      <span style="color:blue;font-weight:bold;"> {{ $t('Email') }} : </span> 
      <span style="color:#000;font-weight:bold;"> 
        {{ statusDetails.email }}
      </span> 
    </p>
  </v-col>


</v-row>

<v-row v-else style="margin-top: 15px;margin-right: 10px;">
    <h2 style="color: red;">{{ $t('NoData') }}</h2>
</v-row>
</div>
</template>


<script>
import moment from 'moment';

export default {
  props: {
    eventName: String,
    oldDetails: Object,
    statusDetails: Object,
    daysOfWeek: Array,
  },
  methods: {
    compareAndHighlight(field) {
      if (!this.oldDetails || !this.statusDetails) {
        return {};
      }

      if (
        Array.isArray(this.oldDetails[field]) &&
        Array.isArray(this.statusDetails[field])
      ) {
        // Compare arrays
        if (
          this.oldDetails[field].length != this.statusDetails[field].length ||
          !this.oldDetails[field].every(
            (value, index) => value == this.statusDetails[field][index]
          )
        ) {
          return { backgroundColor: "yellow", fontWeight: "bold" };
        }
      } else if (field == "start_date" || field == "end_date") {
        // Handle date comparison
        const oldDate = this.dateCreatedAt(this.oldDetails[field]);
        const newDate = this.dateCreatedAt(this.statusDetails[field]);
        if (oldDate != newDate) {
          return { backgroundColor: "yellow", fontWeight: "bold" };
        }
      } else if (this.oldDetails[field] != this.statusDetails[field]) {
        // Handle other fields
        return { backgroundColor: "yellow", fontWeight: "bold" };
      }

      return {};
    },
    dateCreatedAt(created_at) {
      if (created_at) return moment(created_at).format("YYYY-MM-DD");
      else return null;
    },
  },
};
</script>

<style>
</style>