<template>
  <!-- Subscribe Event -->
  <div v-if="eventName == 'subscription_days'">
    <v-row>
      <v-col class="mt-2" :cols="12">
        <div>
          <label
            style="
              font-size: 18px;
              background-color: rgb(213, 33, 13);
              padding: 2px 18px;
              color: #fff;
            "
            >{{ $t("OldData") }}</label
          >
        </div>
      </v-col>

      <v-row v-if="oldDetails" style="margin-top: 15px; margin-right: 10px">
        <v-col :cols="8">
          <p>
            <span style="color: blue; font-weight: bold">
              {{ $t("Days") }} :
            </span>
            <span v-if="oldDetails.length" style="color: #000; font-weight: bold">
              <span
                v-for="(day, index) in oldDetails"
                :key="index"
                :style="compareAndHighlight('days', index)"
              >
                {{
                  daysOfWeek.find((d) => d.id === day)
                    ? daysOfWeek.find((d) => d.id === day).name
                    : ""
                }}
                <span v-if="index !== oldDetails.length - 1">, </span>
              </span>
            </span>
          </p>
        </v-col>
      </v-row>

      <v-row v-else style="margin-top: 15px; margin-right: 10px">
        <h2 style="color: red">{{ $t("NoData") }}</h2>
      </v-row>

      <v-col class="mt-10" :cols="12">
        <div>
          <label
            style="
              font-size: 18px;
              background-color: rgb(11, 195, 24);
              padding: 2px 18px;
              color: #fff;
            "
            >{{ $t("StatusData") }}</label
          >
        </div>
      </v-col>
    </v-row>

    <v-row v-if="statusDetails" style="margin-top: 15px; margin-right: 10px">
      <v-col :cols="8">
        <p>
          <span style="color: blue; font-weight: bold">
            {{ $t("Days") }} :
          </span>
          <span v-if="statusDetails.length" style="color: #000; font-weight: bold">
            <span
              v-for="(day, index) in statusDetails"
              :key="index"
              :style="compareAndHighlight('days', index)"
            >
              {{
                daysOfWeek.find((d) => d.id === day)
                  ? daysOfWeek.find((d) => d.id === day).name
                  : ""
              }}
              <span v-if="index !== statusDetails.length - 1">, </span>
            </span>
          </span>
        </p>
      </v-col>
    </v-row>

    <v-row v-else style="margin-top: 15px; margin-right: 10px">
      <h2 style="color: red">{{ $t("NoData") }}</h2>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    eventName: String,
    oldDetails: Array,
    statusDetails: Array,
    daysOfWeek: Array,
  },
  methods: {
    compareAndHighlight(field, index) {
      if (!this.oldDetails || !this.statusDetails) {
        return {};
      }

      if (Array.isArray(this.oldDetails) && Array.isArray(this.statusDetails)) {
        const oldValue = this.oldDetails[index];
        const newValue = this.statusDetails[index];

        if (oldValue !== newValue) {
          // Highlight differences
          return {
            backgroundColor: "yellow",
            fontWeight: "bold",
          };
        }
      } else if (field === "start_date" || field === "end_date") {
        const oldDate = this.dateCreatedAt(this.oldDetails[field]);
        const newDate = this.dateCreatedAt(this.statusDetails[field]);
        if (oldDate !== newDate) {
          return { backgroundColor: "yellow", fontWeight: "bold" };
        }
      } else if (this.oldDetails[field] !== this.statusDetails[field]) {
        return { backgroundColor: "yellow", fontWeight: "bold" };
      }

      return {};
    },
    dateCreatedAt(created_at) {
      if (created_at) return moment(created_at).format("YYYY-MM-DD");
      else return null;
    },
  },
};
</script>

<style>
</style>
