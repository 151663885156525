<template>
   <div v-if="eventName == 'deliveries'">

<v-row>
<v-col class="mt-2" :cols="12">
  <div>
    <label style="font-size: 18px;
    background-color: rgb(213, 33, 13)
    ;padding: 2px 18px;
    color: #fff;
    
    "
    >{{ $t("OldData") }}</label>
  </div>
</v-col>
            
<v-row v-if="oldDetails" style="margin-top: 15px;margin-right: 10px;">

  <v-col :style="compareAndHighlight('id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('ID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('subscription_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('SubscriptionID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.subscription_id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('period')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Period') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.period }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('status')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Status') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.status }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('address')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Address') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.address }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('city_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('CityID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.city_id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('company_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('CompanyID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.company_id }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('company')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Company') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.company }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('link_map')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('LinkMap') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.link_map }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('branch_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('BranchID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.branch_id }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('driver_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('DriverID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.driver_id }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('address_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('AddressID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.address_id }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('customer_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('CustomerID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.customer_id }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('home_number')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('HomeNumber') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.home_number }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('home_address')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('homeAddress') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.home_address }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('notes')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Notes') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.notes }}
        </span> 
      </p>
    </v-col>


 </v-row>

  <v-row v-else style="margin-top: 15px;margin-right: 10px;">
      <h2 style="color: red;">{{ $t('NoData') }}</h2>
  </v-row>

  <v-col class="mt-10" :cols="12">
    <div>
      <label style="font-size: 18px;
      background-color: rgb(11, 195, 24)
      ;padding: 2px 18px;
      color: #fff;
      
      "
      >{{ $t("StatusData") }}</label>
    </div>
  </v-col>
</v-row>


<v-row v-if="statusDetails" style="margin-top: 15px;margin-right: 10px;">

  <v-col :style="compareAndHighlight('id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('ID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('subscription_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('SubscriptionID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.subscription_id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('period')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Period') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.period }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('status')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Status') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.status }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('address')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Address') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.address }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('city_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('CityID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.city_id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('company_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('CompanyID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.company_id }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('company')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Company') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.company }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('link_map')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('LinkMap') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.link_map }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('branch_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('BranchID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.branch_id }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('driver_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('DriverID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.driver_id }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('address_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('AddressID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.address_id }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('customer_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('CustomerID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.customer_id }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('home_number')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('HomeNumber') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.home_number }}
        </span> 
      </p>
    </v-col>
    <v-col :style="compareAndHighlight('home_address')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('homeAddress') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.home_address }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('notes')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Notes') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.notes }}
        </span> 
      </p>
    </v-col>

</v-row>

<v-row v-else style="margin-top: 15px;margin-right: 10px;">
    <h2 style="color: red;">{{ $t('NoData') }}</h2>
</v-row>
</div>
</template>


<script>
import moment from 'moment';

export default {
  props: {
    eventName: String,
    oldDetails: Object,
    statusDetails: Object,
    daysOfWeek: Array,
  },
  methods: {
    compareAndHighlight(field) {
      if (!this.oldDetails || !this.statusDetails) {
        return {};
      }

      if (
        Array.isArray(this.oldDetails[field]) &&
        Array.isArray(this.statusDetails[field])
      ) {
        // Compare arrays
        if (
          this.oldDetails[field].length != this.statusDetails[field].length ||
          !this.oldDetails[field].every(
            (value, index) => value == this.statusDetails[field][index]
          )
        ) {
          return { backgroundColor: "yellow", fontWeight: "bold" };
        }
      } else if (field == "start_date" || field == "end_date") {
        // Handle date comparison
        const oldDate = this.dateCreatedAt(this.oldDetails[field]);
        const newDate = this.dateCreatedAt(this.statusDetails[field]);
        if (oldDate != newDate) {
          return { backgroundColor: "yellow", fontWeight: "bold" };
        }
      } else if (this.oldDetails[field] != this.statusDetails[field]) {
        // Handle other fields
        return { backgroundColor: "yellow", fontWeight: "bold" };
      }

      return {};
    },
    dateCreatedAt(created_at) {
      if (created_at) return moment(created_at).format("YYYY-MM-DD");
      else return null;
    },
  },
};
</script>

<style>
</style>