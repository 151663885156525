<template>
  <div>
    <div class="breadCurmb mt-3">
  <label>
    <router-link to="/admin/home">{{ $t("Home") }}</router-link>
  </label>
  <span>/</span>
  <label active>{{ $t("WorkGroup") }}</label>
  <span>/</span>
  <label>
    <router-link :to="{ name: 'activities' }">{{ $t("CustomerActivities") }}</router-link>
  </label>
  <span>/</span>
  <label active>{{ $t("CustomerDetails") }}</label>
</div>


    <v-container fluid>
      <h2>{{ $t("CustomerDetails") }}</h2>
      <v-row class="mt-0" v-if="details && details.customer_data">
        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>{{ $t('SubscriberName') }}: 
              <span> {{ details.customer_data.name }} </span>
            </p>
          </div>
        </v-col>

        

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>{{ $t('Email') }}: 
              <span>  {{ details.customer_data.email }} </span>
            </p>
          </div>
        </v-col>


        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>{{ $t('Mobile') }}: 
                <span> 
                  {{ details.customer_data.phone_number }}
                </span>
            </p>
          </div>
        </v-col>

        <!-- <v-col cols="12" sm="4">
          <div class="customCard">
            <p>{{ $t('Mobile') }} 2: 
                <span> 
                  {{ details.customer_data.mobile }}
                </span>
            </p>
          </div>
        </v-col> -->


      </v-row>

      <br><br>
       

      <div v-if="isLoad == 2">


<v-row>


  <v-col :md="2">
        <div>
          <label>{{$t('LogType')}}</label>
         <v-autocomplete
            :clearable="true"
            class="d-block my-2"
            name="type"
            hide-details
            dense
            outlined
            :multiple="false"
            item-text="name"
            item-value="id"
            v-model="filters2.log_name"
            :items="logTypeList"
          ></v-autocomplete>
        </div>
      </v-col>

  <!-- <v-col :md="2">
      <div>
        <label>{{$t('Event')}}</label>
      <v-text-field
          class="d-block my-2"
          name="event"
          hide-details
          dense
          outlined
          v-model="filters2.event"
        ></v-text-field>
      </div>
      </v-col> -->


  <v-col :md="2">
    <div>
      <label>{{ $t("FromDate") }}</label>
      <el-date-picker
          style="width: 100%"
          v-model="filters2.from_date"
          type="date"
          class="mt-2"
          name="from_date"
          data-vv-scope="addEditValidation"
          v-validate="''"
          :data-vv-as="$t('FromDate')"
          hide-details
          dense
          outlined
        >
        </el-date-picker>
    </div>
  </v-col>

  <v-col :md="2">
    <div>
      <label>{{ $t("ToDate") }}</label>
      <el-date-picker
          style="width: 100%"
          v-model="filters2.to_date"
          type="date"
          class="mt-2"
          name="from_date"
          data-vv-scope="addEditValidation"
          v-validate="''"
          :data-vv-as="$t('ToDate')"
          hide-details
          dense
          outlined
        >
        </el-date-picker>
    </div>
  </v-col>

<v-col :md="1" class="mt-6">
<v-btn
  color="third"
  hide-details
  @click="getDataFromApi2"
  style="display:block;margin-top:10px;"
  class="w-full h-btn white-clr"
  small
>
  {{ $t("Filter") }}
</v-btn>
</v-col>
<v-col :md="1" class="mt-6">
<v-btn
  color="danger"
  hide-details
  @click="clearFilters2();isLoad=2;loadCustomerLogs();"
  style="display:block;margin-top:10px;"
  class="w-full h-btn white-clr"
  small
>
  {{ $t("Clear") }}
</v-btn>
</v-col>
</v-row>


</div>



<!-- End Filters -->


<!-- Start DataTable -->

<br>

<v-data-table
:headers="table2.headers"
:items="table2.items"
:loading="table2.loading"
:items-per-page="table2.itemsPerPage"
hide-default-footer
class="elevation-1"
:loading-text="$t('LoadData')"
>
<template v-slot:no-data>
  <v-alert class="mt-5 white-clr" color="third">
    {{ $t("NoData") }}
  </v-alert>
  
</template>


<template v-slot:item.actions="{ item }">
        <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="openModalDetails(item)"
          color="success"
          >mdi-eye</v-icon
        >
      </template>





<template v-slot:item.created_at="{ item }">
      <p>{{ dateCreatedAt(item.created_at) }}</p>
</template>


<!-- <template v-slot:item.actions="{ item }">
  <v-icon
    class="my-0 mr-2 ml-2"
    dense
    @click="goToCustomerActivitiesDetails(item)"
    color="success"
    >mdi-eye</v-icon
  >
</template> -->



</v-data-table>

<!-- End DataTable -->

<!-- Start Pagination -->
<div v-if="isLoad == 2" class="text-center pt-2">
<v-pagination
  v-if="table2.length"
  total-visible="5"
  v-model="table2.page"
  :length="table2.length"
  @input="changePage2(table2.page)"
></v-pagination>
</div>

<!-- End Pagination -->


<v-dialog
        v-model="detailsDialog"
        width="80rem"
        @click:outside="closeModal"
      >
    <div v-if="detailsDialog">
    <v-card>
    <v-card-text>     

    <v-container fluid>
        
     
    <HeaderEvent :details="details" />

      <component
          :is="eventComponent"
          :eventName="eventName"
          :oldDetails="oldDetails"
          :statusDetails="statusDetails"
          :daysOfWeek="daysOfWeek"
      />

    </v-container>
            
     </v-card-text>

    </v-card>
  </div>
 </v-dialog>


    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import HeaderEvent from './event_components/HeaderEvent.vue';
import SubscribeEvent from './event_components/SubscribeEvent.vue';
import ProfileEvent from './event_components/ProfileEvent.vue';
import TransactionEvent from './event_components/TransactionEvent.vue';
import AddressEvent from './event_components/AddressEvent.vue';
import CustomersEvent from './event_components/CustomersEvent.vue';
import FailedTransactionEvent from './event_components/FailedTransactionEvent.vue';
import SubscriptionDayEvent from './event_components/SubscriptionDayEvent.vue';
import NewGroupsEvent from './event_components/NewGroupsEvent.vue';

import DelivariesEvent from './event_components/DelivariesEvent.vue';
export default {
  name: "subscribeDetails",
  components: {
    HeaderEvent,
    SubscribeEvent,
    ProfileEvent,
    TransactionEvent,
    AddressEvent,
    CustomersEvent,
    FailedTransactionEvent,
    SubscriptionDayEvent,
    NewGroupsEvent,
    DelivariesEvent
  },
  computed: {
    eventComponent() {
      const eventMap = {
        subscription: 'SubscribeEvent',
        profile: 'ProfileEvent',
        transaction: 'TransactionEvent',
        address: 'AddressEvent',
        customers: 'CustomersEvent',
        'failed-transaction': 'FailedTransactionEvent',
        deliveries: 'DelivariesEvent',
        'subscription_days': 'SubscriptionDayEvent',
        'new_groups': 'NewGroupsEvent',
      };

      console.log(this.eventName);
      console.log(eventMap[this.eventName]);

      // Return the component name based on the eventName, fallback to null if not found
      return eventMap[this.eventName] || null;
    }
  },
  data() {
    return {
    eventName: '',
  daysOfWeek : [
    { id: 6, name: this.$t("Saturday") },
    { id: 0, name: this.$t("Sunday") },
    { id: 1, name: this.$t("Monday") },
    { id: 2, name: this.$t("Tuesday") },
    { id: 3, name: this.$t("Wednesday") },
    { id: 4, name: this.$t("Thursday") },
    { id: 5, name: this.$t("Friday") },
  ],


      detailsDialog: false,
      oldDetails: null,
      statusDetails: null,
      logTypeList: [
        {
          id: 'subscription',
          name: this.$t('Subscriptions') ,
        },
        {
          id: 'deliveries',
          name: this.$t('Deliveries') ,
        },
        {
          id: 'transaction',
          name: this.$t('Transactions') ,
        },
        {
          id: 'failed-transaction',
          name: this.$t('FailedTransactions') ,
        },
        {
          id: 'customers',
          name: this.$t('Customers') ,
        },
        {
          id: 'address',
          name: this.$t('Addresses') ,
        },
        {
          id: 'profile',
          name: this.$t('Profiles') ,
        },
        {
          id: 'subscription_days',
          name: this.$t('SubscriptionDays') ,
        },
        {
          id: 'new_groups',
          name: this.$t('NewGroups') ,
        },
      ],
      fromPage: '',
      isLoad: 2,
      id: this.$route.params.id || null,
      details: null,
      filters2: {
        log_name: null,
        // event: null,
        from_date: null,
        to_date: null,
      },

      table2: {
        page: 1,
        length: null,
        itemsPerPage: 15,
        loading: false,
        items: [],
        headers: [
          { text: "#", value: "id", width: "5rem" },
          {
            text: this.$t("LogType"),
            value: "log_name",
            width: "10rem",
          },
          {
            text: this.$t("Event"),
            value: "event",
            width: "10rem",
          },
          // { text: "causer_id", value: "causer_id", width: "5rem" },
          // {
          //   text: this.$t("CauserType"),
          //   value: "causer_type",
          //   width: "10rem",
          // },

          // {
          //   text: this.$t("subject_id"),
          //   value: "subject_id",
          //   width: "10rem",
          // },

          // {
          //   text: this.$t("SubjectType"),
          //   value: "subject_type",
          //   width: "10rem",
          // },

          // {
          //   text: this.$t("BatchUID"),
          //   value: "batch_uuid",
          //   width: "10rem",
          // },

          {
            text: this.$t("Description"),
            value: "description",
            width: "10rem",
          },

          {
            text: this.$t("CreatedAt"),
            value: "created_at",
            width: "10rem",
          },
          {
            text: this.$t("Actions"),
            value: "actions",
            sortable: false,
            align: "center",
            width: "8rem",
          }
        ],
      },

    };

  },
  methods: {
    closeModal() {
      this.detailsDialog = false;
      this.oldDetails= null;
      this.statusDetails= null;
    },
    openModalDetails(prop) {
      this.eventName= '';
      this.eventName= prop.log_name;
     if(prop.old) this.oldDetails= prop.old;
     if(prop.new) this.statusDetails= prop.new;
      this.detailsDialog= true;
    },
    dateCreatedAt(created_at) {
      if (created_at) return moment(created_at).format("YYYY-MM-DD");
      else return null;
    },
    changePage2(page) {
      this.table2.page = page;
      this.getDataFromApi2();
    },
    clearFilters2() {
      for (let key in this.filters2) {
        this.filters2[key] = null;
      }
    },
    
    loadCustomerLogsDetails() {
      this.getDataFromApi2();
    },

    getDataFromApi2() {
      this.table2.loading = true;

      if (this.$route.params.id) {
      const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });

          let sendData = {...this.filters2, page: this.table2.page, id:  this.$route.params.id};

          if(sendData.from_date) sendData.from_date= moment(this.filters2.from_date).format('yyyy-MM-DD');
          if(sendData.to_date) sendData.to_date= moment(this.filters2.to_date).format('yyyy-MM-DD');

        this.$store
            .dispatch(`activity/getData2Details`, sendData)
            .then((res) => {
              this.details = res.data;
              this.table2.items = res.data.customer_logs.data;

              this.table2.length = Math.ceil(res.data.customer_logs.total / res.data.customer_logs.per_page);

            }).finally( () => {
              this.table2.loading = false;
              loading.close();
            })
    }
    },
  },
  created() {
    let  customer_details_from= localStorage.getItem('customer_details_from') || 'customers';
    this.fromPage= customer_details_from;
    localStorage.setItem('activateLogsPage', 'activateLogsPageInside')
  },
  mounted() {
    this.loadCustomerLogsDetails();
  }
};
</script>


<style>


.customCard {
  width: 100%;
  height: 60px;
  background: #edfde4;
  border-radius: 4px;
  color: #222;
  font-weight: bold;
  border: 1px solid #ccc;
  text-align: center;
}
.card1 {
  height: auto;
}
.font-big-bold {
  font-weight: bold;
  font-size: 16px;
  padding: 20px 15px;
}
.customCard p {
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
}
.val {
  color: blue;
}
.emptyVal {
  color: red;
}

.v-dialog:not(.v-dialog--fullscreen) {
  z-index: 99;
}
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined)
,#adminApp .theme--light.v-navigation-drawer
{
  z-index: 98 !important;
}

</style>