<template>
  

     <!-- transaction Event -->
     <div v-if="eventName == 'transaction'">

<v-row>
<v-col class="mt-2" :cols="12">
  <div>
    <label style="font-size: 18px;
    background-color: rgb(213, 33, 13)
    ;padding: 2px 18px;
    color: #fff;
    
    "
    >{{ $t("OldData") }}</label>
  </div>
</v-col>
            
<v-row v-if="oldDetails" style="margin-top: 15px;margin-right: 10px;">

  <v-col :style="compareAndHighlight('id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('ID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('subscription_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('SubscriptionID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.subscription_id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('transaction_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('TransactionID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.transaction_id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('amount')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Amount') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ oldDetails.amount }}
        </span> 
      </p>
    </v-col>


 </v-row>

  <v-row v-else style="margin-top: 15px;margin-right: 10px;">
      <h2 style="color: red;">{{ $t('NoData') }}</h2>
  </v-row>

  <v-col class="mt-10" :cols="12">
    <div>
      <label style="font-size: 18px;
      background-color: rgb(11, 195, 24)
      ;padding: 2px 18px;
      color: #fff;
      
      "
      >{{ $t("StatusData") }}</label>
    </div>
  </v-col>
</v-row>


<v-row v-if="statusDetails" style="margin-top: 15px;margin-right: 10px;">

  <v-col :style="compareAndHighlight('id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('ID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('subscription_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('SubscriptionID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.subscription_id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('transaction_id')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('TransactionID') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.transaction_id }}
        </span> 
      </p>
    </v-col>

    <v-col :style="compareAndHighlight('amount')" :cols="4">
      <p> 
        <span style="color:blue;font-weight:bold;"> {{ $t('Amount') }} : </span> 
        <span style="color:#000;font-weight:bold;"> 
          {{ statusDetails.amount }}
        </span> 
      </p>
    </v-col>

</v-row>

<v-row v-else style="margin-top: 15px;margin-right: 10px;">
    <h2 style="color: red;">{{ $t('NoData') }}</h2>
</v-row>
</div>


</template>

<script>
import moment from 'moment';

export default {
  props: {
    eventName: String,
    oldDetails: Object,
    statusDetails: Object,
    daysOfWeek: Array,
  },
  methods: {
    compareAndHighlight(field) {
      if (!this.oldDetails || !this.statusDetails) {
        return {};
      }

      if (
        Array.isArray(this.oldDetails[field]) &&
        Array.isArray(this.statusDetails[field])
      ) {
        // Compare arrays
        if (
          this.oldDetails[field].length != this.statusDetails[field].length ||
          !this.oldDetails[field].every(
            (value, index) => value == this.statusDetails[field][index]
          )
        ) {
          return { backgroundColor: "yellow", fontWeight: "bold" };
        }
      } else if (field == "start_date" || field == "end_date") {
        // Handle date comparison
        const oldDate = this.dateCreatedAt(this.oldDetails[field]);
        const newDate = this.dateCreatedAt(this.statusDetails[field]);
        if (oldDate != newDate) {
          return { backgroundColor: "yellow", fontWeight: "bold" };
        }
      } else if (this.oldDetails[field] != this.statusDetails[field]) {
        // Handle other fields
        return { backgroundColor: "yellow", fontWeight: "bold" };
      }

      return {};
    },
    dateCreatedAt(created_at) {
      if (created_at) return moment(created_at).format("YYYY-MM-DD");
      else return null;
    },
  },
};
</script>

<style>
</style>